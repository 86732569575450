import clsx from "clsx"

type CheckboxProps = {
  label?: string
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export const Checkbox = ({
  className,
  id,
  label,
  disabled,
  ...props
}: CheckboxProps) => {
  return (
    <div className={className}>
      <label
        className={clsx(
          "flex items-center gap-[10px]",
          !disabled && "cursor-pointer",
        )}
        htmlFor={id}
      >
        <input
          className={clsx(
            "opacity-0 absolute w-[20px] h-[20px]",
            !disabled && "cursor-pointer",
          )}
          type="checkbox"
          id={id}
          disabled={disabled}
          {...props}
        />

        <div
          className={clsx(
            "bg-white border border-ash rounded w-[20px] h-[20px] flex flex-shrink-0 justify-center items-center focus-within:border-steel focus-within:bg-steel",
            disabled && "opacity-25",
          )}
        >
          <svg
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6171 0.483459L4.7369 7.51658L1.38306 4.71988"
              stroke="white"
              strokeWidth="1.5"
              strokeMiterlimit="10"
            />
          </svg>
        </div>

        {!!label && <span className="text-steel">{label}</span>}
      </label>
    </div>
  )
}
